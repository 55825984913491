/* css for font poppins start */
@font-face {
    font-family: regular;
    src: url(../public/assets/font/Poppins/Poppins-Regular.ttf);
}

@font-face {
    font-family: medium;
    src: url(../public/assets/font/Poppins/Poppins-Medium.ttf);
}

@font-face {
    font-family: semibold;
    src: url(../public/assets/font/Poppins/Poppins-SemiBold.ttf);
}

@font-face {
    font-family: bold;
    src: url(../public/assets/font/Poppins/Poppins-Bold.ttf);
}

@font-face {
    font-family: extrabold;
    src: url(../public/assets/font/Poppins/Poppins-ExtraBold.ttf);
}

@font-face {
    font-family: light;
    src: url(../public/assets/font/Poppins/Poppins-Light.ttf);
}

@font-face {
    font-family: extralight;
    src: url(../public/assets/font/Poppins/Poppins-ExtraLight.ttf);
}

.semibold {
    font-family: semibold;
}

.medium {
    font-family: medium;
}

.light {
    font-family: light;
}

/* css for font poppins end*/

/* common css start  */

/* color code css start  */
:root {
    --theme-grad-color: #20b2aa;
}

/* color code css end */


* {
    margin: 0px;
    padding: 0px;
}

.page h1,
.page h2,
.page h3,
.page h4,
.page h5,
.page h6,
.page p {
    margin: 0px;
}

.page a {
    text-decoration: none;
}

.my_center {
    text-align: center;
}

.verticall_gap {
    height: 32px;
}

body {
    font-family: regular;
    color: black;
}

.vendor_page_spacing {
    padding: 15px;
    max-width: 420px;
    min-height: calc(100vh - 55px);
    width: 100%;
    margin: auto;
    margin-top: 55px;
}

.from_top {
    margin-top: -70px;

}

.from_left_right {
    margin-left: -15px;
    margin-right: -15px;
}



.pl0 {
    padding-left: 0px;
}

.m15 {
    font-size: 15px;
    font-family: medium;
}

.m14 {
    font-size: 14px;
    font-family: medium;
}

.m22 {
    font-size: 22px;
    font-family: medium;
}

.m10 {
    font-size: 10px;
    font-family: medium;
}

.m16 {
    font-size: 16px;
    font-family: medium;
}

.m18 {
    font-size: 18px;
    font-family: medium;
}

.sb22 {
    font-size: 22px;
    font-family: semibold;
}

.sb12 {
    font-size: 12px;
    font-family: semibold;
}

.sb25 {
    font-size: 25px;
    font-family: semibold;
}

.sb30 {
    font-size: 30px;
    font-family: semibold;
}

.sb35 {
    font-size: 35px;
    font-family: semibold;
}

.sb40 {
    font-size: 40px;
    font-family: semibold;
}

.r18 {
    font-size: 18px;
    font-family: regular;
}

.r14 {
    font-size: 14px;
    font-family: regular;
}

.icon14 {
    font-size: 14px;
    margin-right: 2px;
}

.img14 {
    height: 13px;
    width: auto;
    margin-right: 4px;
}

.lh22 {
    line-height: 22px;
}

.r16 {
    font-size: 16px;
    font-family: regular;
}

.r20 {
    font-size: 20px;
    font-family: regular;
}

.r12 {
    font-size: 12px;
    font-family: regular;
}

.r10 {
    font-size: 10px;
    font-family: regular;
}

.m18 {
    font-size: 18px;
    font-family: medium;
}

.m20 {
    font-size: 20px;
    font-family: medium;
}

.m12 {
    font-size: 12px;
    font-family: medium;
}

.sb16 {
    font-size: 16px;
    font-family: semibold;
}

.sb14 {
    font-size: 14px;
    font-family: medium;
}

.b14 {
    font-size: 14px;
    font-family: bold;
}

.vg22 {
    width: 100%;
    height: 22px;
}

.vg8 {
    width: 100%;
    height: 8px;
}

.vg6 {
    width: 100%;
    height: 6px;
}

.vg3 {
    width: 100%;
    height: 3px;
}

.vg12 {
    width: 100%;
    height: 12px;
}

.vg16 {
    width: 100%;
    height: 16px;
}

.vg30 {
    width: 100%;
    height: 30px;
}

.ff_gap {
    width: 100%;
    height: 13px;
}

.page .theme_btn {
    text-decoration: none;
    background: transparent linear-gradient(180deg, #7D4444 0%, #FF0000 100%);
    border: 1px solid #FFFFFF;
    border-radius: 9px;
    padding: 15px 15px;
    display: block;
    color: white;
    font-size: 16px;
    font-family: regular;
    width: 100%;
    text-align: center;
}

.page .theme_btn.short_btn.view_btn {
    padding: 2px 15px;
    font-size: 12px;
    border-radius: 4px;
}

.page .theme_btn.short_btn.margin_auto {
    margin-left: auto;
    margin-right: auto;
}

.page .theme_btn.text_near_btn {
    border-radius: 3px;
    padding: 4px 8px;
    font-size: 10px;
    width: fit-content;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    gap: 3px;
}

.page .theme_btn.text_near_btn span {
    font-size: 10px;
}

.page .theme_btn.btn_border {
    background: white;
    color: #FF0000;
    border-color: #FF0000;
}

.page .theme_btn.short_btn {
    width: fit-content;
    padding: 15px 28px;

}

.page .theme_btn.short_btn_2 {
    width: fit-content;
    padding: 12px 18px;
    font-size: 14px;
}

.page .theme_btn.btn_with_icon {
    display: flex;
    justify-content: center;
    gap: 4px;
    align-items: center;
}

.page .theme_btn.btn_with_icon .btn_icon {
    font-size: 16px;
}

.page .theme_btn.short_btn_2.btn_with_icon .btn_icon {
    font-size: 18px;
}

.align_center_pg {
    min-height: calc(100vh - 100px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.acp_inner {
    width: 100%;
}


.vendor_page_spacing .logo {
    width: auto;
    height: 90px;
    margin: auto;
}

.vendor_page_spacing .logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.red {
    color: #FF0000;
}

.purple {
    color: #FF0000;
}

.blue {
    color: #008DEA;
}

.white {
    color: white;
}

.black {
    color: black;
}

.grey {
    color: #807E7E;
}

.green {
    color: #269B02;
}

.form_field label {
    font-size: 14px;
    font-family: 'regular';
    display: block;
    margin-bottom: 3px;
    color: #000000;
}

.form_field label.field_optional {
    color: #807e7e;
}

.form_field label span {
    font-size: 8px;
    color: #807E7E;
}

.radio_group {
    display: grid;
    row-gap: 5px;
}

.inline_radio_group {
    display: flex;
    align-items: center;
    gap: 15px;
}

.inline_radio_group.with_bg {
    background: #EFEFEF;
    padding: 12px;
    border-radius: 9px;
    margin-top: 5px;
}

.radio_field label {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    font-family: regular;
    text-transform: capitalize;
}

.radio_field label span {
    font-size: 10px;
    font-family: regular;
    color: #807E7E;
}

.form_field input,
.form_field select,
.form_field textarea {
    font-size: 14px;
    font-family: 'regular';
    padding: 10px;
    width: 100%;
    border: 1px solid black;
    border-radius: 9px;
}

.input_with_country {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: 'regular';
    padding: 10px;
    width: 100%;
    border: 1px solid black;
    border-radius: 9px;
    position: relative;
}

.input_with_country::after {
    content: '';
    background: #000000;
    position: absolute;
    width: 1px;
    height: 100%;
    left: 45px;
    top: 0;
}

.input_with_country input {
    border: none;
    padding: 0px;
    margin-left: 10px;
}

.input_with_country input:focus {
    outline: none;
    border: none !important;
}

.form_field textarea {
    min-height: 100px;
}

::placeholder {
    opacity: 0.4;
}

.form_field input:focus,
.form_field select:focus,
.form_field textarea:focus {
    outline: none;
    border: 1px solid black;
}

.grad_text {
    background: transparent linear-gradient(180deg, #7D4444 0%, #FF0000 100%);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    cursor: pointer;
    font-size: 14px;
}

.img_wrapper .edit {
    background: white;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    /* padding: 18px; */
    position: absolute;
    bottom: 10px;
    right: -14px;
    cursor: pointer;
}


.img_wrapper .edit span {
    background: transparent linear-gradient(180deg, #7D4444 0%, #FF0000 100%);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

.gt_border {
    position: relative;
    display: inline-block;
}

.v_slider_img {
    display: flex;
    align-items: center;
    gap: 6px;
}

.vsi_single {
    width: 100px;
    aspect-ratio: 3/2;
    cursor: pointer;
    position: relative;
}

.vsi_single .close_media {
    height: 22px;
    top: -10px;
    right: 0px;
}

.add_more_items_div {
    background: #EFEFEF;
    padding: 10px;
    border: none;
    font-size: 14px;
    border-radius: 9px;
    margin-top: 22px;
}

.add_more_items_div .close_media {
    top: -10px;
    right: -5px;
}

.upload_product_img .img_wrapper {
    width: 50px;
    height: 50px;
    border-radius: 9px;
    border: 1px solid black;
    position: relative;
}

.upload_product_img .img_wrapper img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 9px;
}

.upload_product_img .img_wrapper .delete {
    position: absolute;
    top: -4px;
    right: -4px;
    border-radius: 50%;
    background: white;
    width: 15px;
    height: 15px;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.upload_product_img .img_wrapper .delete span {
    color: red;
    font-size: 15px;
}

.upload_product_img {
    display: flex;
    align-items: center;
    gap: 7px;
}



.vsi_single img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 7px;
}

.gt_border::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: transparent linear-gradient(180deg, #7D4444 0%, #FF0000 100%);
    left: 0;
    bottom: 0px;

}

.add_more {
    position: absolute;
    bottom: -22px;
    right: 15px;
}

.form_field .checkbox {
    display: flex;
    align-items: start;
    gap: 5px;
}

.loginnumber .PhoneInputCountry {
    padding: 10px;
    width: 20%;
    border: 1px solid black;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.otp_input input {
    margin: 0px !important;
    width: 45px !important;
    height: 45px !important;
    margin-top: 4px !important;
    border-radius: 9px !important;
}

.otp_input {
    justify-content: center;
    gap: 15px;
}

.text_left {
    text-align: left;
}

.my_filter {
    display: flex;
    gap: 6px;
    align-items: center;
    width: 70px;
    margin-left: 14px;
    cursor: pointer;
}

.my_filter.filter_end {
    margin-left: auto;
}

.my_filter img {
    height: 14px;
}

.search_with_filter {
    display: flex;
    align-items: end;
}

.search_with_filter .form_field {
    width: 100%;
}

.form_field .checkbox input {
    width: fit-content;
    margin-top: 4.5px;
}

.form_field .checkbox label {
    font-size: 14px;
    margin-bottom: 0px;
}

.relative {
    position: relative;
}

.pointer {
    cursor: pointer;
}

.text_capital {
    text-transform: capitalize;
}

.big_cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.back_div {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    color: black;
}

section.top_row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.close_media {
    background: #FF7474;
    width: 25px !important;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    border-radius: 50%;
    position: absolute;
    top: -12px;
    right: 5px;
    cursor: pointer;
}

.close_media span {
    color: white;
    font-size: 18px;
}

.bc_single {
    background: #BCBCBC;
    border-radius: 12px;
    padding: 15px;
    text-align: center;
    min-height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.bc_single .top {
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    margin: auto;
}

.bc_single.select_portal .top {
    display: none;
}

.pg_chooseplan .bc_single {
    min-height: 145px;
}


.bc_single .bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 4px;
    font-size: 16px;
    font-family: 'medium';
    color: white;
    background: #008DEA;
    border-radius: 0px 0px 12px 12px;
}

.bc_single span.check {
    position: absolute;
    top: 12px;
    right: 12px;
}

.down_indicate {
    width: 15px;
    height: 13px;
    background: #FF0000;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    transform: rotate(180deg);
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    bottom: -12px;
}

.bc_single.select_portal {
    background: transparent linear-gradient(180deg, #7D4444 0%, #FF0000 100%);
    color: white;
}

.bc_single .bcs_inner span {
    font-size: 35px;
}

.small_card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 15px;
}

.sc_single {
    background: #EFEFEF;
    border-radius: 5px;
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;
}

.sc_single .csoon {
    position: absolute;
    display: none;
    background: #008DEA;
    font-size: 10px;
    color: white;
    border-radius: 30px;
    padding: 3px 7px;
    bottom: -10px;
    left: 0;
    right: 0;
    margin: auto;
    width: fit-content;
    z-index: 2;
}

.cs_cat .sc_single .csoon {
    display: block;
}
.cs_cat {
    display: none;
}
.cs_cat .sc_single:after {
    background: black;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    opacity: 0.3;
}

.service_img img {
    height: 20px;
    width: auto;
}

.sc_single span {
    font-size: 18px;
}

.sc_single.select_bussiness {
    background: transparent linear-gradient(180deg, #7D4444 0%, #FF0000 100%);
    color: white;
}

.icon_grad {
    background: transparent linear-gradient(180deg, #7D4444 0%, #FF0000 100%);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    cursor: pointer;
}

.ab_divider {
    margin: 10px 0px;
    background: #B9B9B9;
    height: 1px;
    margin-left: -15px;
    margin-right: -15px;
}

.bottom_plus {
    background: transparent linear-gradient(180deg, #7D4444 0%, #FF0000 100%);
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    position: fixed;
    bottom: 15px;
    right: 15px;
    cursor: pointer;
    z-index: 999;
}

.sect_divider {
    margin: 15px 0px;
    background: #707070;
    height: 1px;

}

.only_one_line {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.only_two_line {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.long_card_single {
    padding: 15px;
    background: #EFEFEF;
    position: relative;
}

/* custom tab start  */
.email_password_form {
    border-radius: 10px;
    overflow: hidden;
}

.email_password_form .inner {
    background: #EFEFEF;
    padding: 25px 15px 15px 15px;
}

.custom_tab {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

}

.custom_tab .ct_single {
    background: black;
    color: white;
    padding: 16px;
    text-align: center;
    letter-spacing: 0.4px;
    cursor: pointer;
    
}
.custom_tab .ct_single h6{
    opacity: 0.75;
}
.custom_tab .ct_single.active h6 {    opacity: 1;
  
}
.custom_tab .ct_single.active { 
    position: relative;
}

.custom_tab .ct_single.active:after {
    content: '';
    position: absolute;
    width: 13px;
    height: 12px;
    background: black;
    bottom: -11px;
    left: 0;
    right: 0;
    margin: auto;
    clip-path: polygon(0 0, 50% 100%, 100% 0);
}

/* custom tab end  */

/* carousel css start  */
.page .slick-dots {
    bottom: 15px;
}

.page .slick-dots li {
    width: 10px;
    height: 10px;
}

.page .slick-dots li button {
    width: 10px;
    height: 10px;
    padding: 0px;
    border: 1px solid black;
    background: white;
    border-radius: 50%;
}

.page .slick-dots li button:before {
    line-height: 0px;
    width: 100%;
    height: 100%;
    content: '';
    opacity: 0;
    background: black;
}

.page .slick-dots li.slick-active button:before {
    opacity: 1;
    color: black;
    border-radius: 50%;
    border: 1px solid white;
}

.diff_action {
    display: flex;
    gap: 26px;
    flex-wrap: wrap;
    align-items: center;
}



/* accordion css start  */
.theme_accordion.accordion {
    display: grid;
    row-gap: 7px;
}

.theme_accordion button.accordion-button {
    background: #EFEFEF;
    padding: 10px 10px 10px 16px;
    border: none;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

.theme_accordion.style_2 button.accordion-button,
.theme_accordion.style_2 .accordion-body {
    background: #EFEFEF;
}

.theme_accordion.style_2 .accordion-body p {
    line-height: 22px;
}

.theme_accordion.style_2 .accordion-body .height_limit {
    max-height: 285px;
    overflow-y: auto;
}

.theme_accordion .accordion-button:focus {
    box-shadow: none;
    outline: none;
    border: none;
}


.theme_accordion .accordion-item {
    border: none;
}

.theme_accordion .accordion-body {
    background: #EFEFEF;
    padding: 10px;
    border: none;
    font-size: 14px;
}

.theme_accordion.style_2 .accordion-body {
    padding: 15px;
}

.theme_accordion.style_2 .accordion-body ul li {
    list-style-type: none;
    position: relative;
    word-break: break-all;
}

.theme_accordion.style_2 .accordion-body ul {
    display: grid;
    row-gap: 5px;
}

.theme_accordion.style_2 .accordion-body ul li span {
    font-size: 20px;
    position: absolute;
    top: 0;
    left: -22px;
}

.theme_accordion h5.product_name {
    font-size: 14px;
    font-family: 'regular';
    line-height: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.accordion_big .theme_accordion h5.product_name {
    display: block;
}

.pg_faq .theme_accordion h5.product_name {
    display: inherit;
    line-height: 20px;

}

.theme_accordion.style_2 h5.product_name {
    color: #008DEA;
    font-size: 16px;
    line-height: 22px;
}

.theme_accordion h6.product_id {
    font-size: 10px;
    font-family: medium;
    color: #807E7E;
}

.theme_accordion .accordion-button .close {
    display: none;
}

.theme_accordion .accordion-button:not(.collapsed) .open {
    display: none;
}

.theme_accordion .accordion-button:not(.collapsed) .close {
    display: block;
}

.theme_accordion .accordion-button::after {
    content: none;
}

.theme_accordion button.accordion-button .right {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.my_accordion_body {
    background: white;
    border-radius: 9px;
    padding: 15px;
}

.product_short_info .pic_price {
    display: flex;
    gap: 8px;
    flex-direction: column;
}

.packageInfo .pic_price {
    flex-direction: row;
    align-items: center;
}

.packageInfo .pic_price .pic {
    width: auto !important;
    height: auto !important;
}

.packageInfo .pic_price .pic img {
    width: 48px !important;
    height: 48px !important;
    object-fit: cover !important;
    border-radius: 8px;
}

.vehicleInfo .pic_price {
    flex-direction: row;
    align-items: center;
}

.vehicleInfo .pic_price .pic {
    width: auto !important;
    height: auto !important;
}

.bottom_address.serviceInfo {
    display: none;
}

.vehicleInfo .pic_price .pic img {
    width: 48px !important;
    height: 48px !important;
    object-fit: cover !important;
    border-radius: 8px;
}

.myvehicle .pic_price {
    flex-direction: inherit !important;
    align-items: center;
}

.serviceInfo .pic_price {
    display: none;
}

.tt_vehicle_single.product_short_info .pic_price {
    gap: 10px;
}

.tt_vehicle_single.product_short_info .price {
    display: flex;
    flex-direction: column;
    gap: 3px;

}

.tt_ss_top {
    display: flex;
    align-items: center;
    gap: 12px;
}

.vehicleInfo h6 span.purple {
    font-size: 18px;
}

.vehicleInfo h6 span.r14 {
    font-size: 16px;
}

.vehicleInfo .r12 {
    font-size: 14px;
}

.packageInfo h6 span.purple,
.packageInfo h6.purple {
    font-size: 18px !important;
}

.packageInfo h6 span.r14 {
    font-size: 16px;
}

.packageInfo .r12 {
    font-size: 14px;
}

.tt_ss_top img {
    width: 62px;
    height: 62px;
}

.product_short_info .pic_price .pic {
    width: 45px;
    height: 50px;
}

.myvehicle .product_short_info .pic_price .pic {
    width: inherit;
    height: inherit;
}

.product_short_info .pic_price .pic img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.myvehicle .product_short_info .pic_price .pic img {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 8px;
}

.psdetail .product_short_info .pic_price .pic img {
    object-fit: contain;
    max-width: 420px;
    width: 100%;
    max-height: 350px;
    border-radius: 8px;

}

.psdetail .product_short_info .pic_price .pic {
    width: auto;
    height: auto;

}

.product_short_info .view {
    position: absolute;
    top: 0;
    right: 0;
}

.product_short_info .name {
    padding-right: 22px;
}


/* accordion css end  */

/* modal css start  */
.my_modal .modal-header,
.my_modal .modal-body,
.my_modal .modal-footer {
    padding: 0px;
    border: none;
}

.my_modal .modal-header {
    display: block;
    position: relative;
}

.my_modal .modal-content {
    padding: 15px;
    text-align: center;
    border-radius: 15px;
}

.my_modal.vdw_modal .modal-content {
    text-align: left;
}

.my_modal.vdw_modal .modal-header {
    text-align: center;
}

.my_modal button.btn-close {
    display: none;
}

.my_modal .modal_close {
    font-size: 23px;
    position: absolute;
    top: -8px;
    right: -8px;
    cursor: pointer;
}


.modal_btn {
    text-decoration: none;
    background: transparent linear-gradient(180deg, #7D4444 0%, #FF0000 100%);
    border: 1px solid;
    border-radius: 9px;
    padding: 15px 15px;
    display: block;
    color: white;
    font-size: 16px;
    font-family: regular;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.modal_btn.modal_top_btn {
    padding: 8px;
    font-size: 14px;
    width: fit-content;
    min-width: 75px;
}

.modal_btn.modal_btn_border {
    background: white;
    color: #FF0000;
    border-color: #FF0000;
}

.crop_modal .modal_btn {
    padding: 8px;
    font-size: 14px;
    width: fit-content;
    min-width: 75px;
}

/* modal css end  */

/* my tabs css start  */
.my_tab .nav-tabs {
    gap: 9px;
    border: none !important;
}

.my_tab .nav-item {
    width: 48%;
}

.my_tab .nav-item button {
    padding: 0px;
    padding-bottom: 15px;
    margin: auto;
    border: none !IMPORTANT;
    width: 100%;
    position: relative;
    cursor: pointer;
    color: #807E7E;
}

.my_tab .nav-item button:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background: #807E7E;
}

.my_tab .nav-tabs .nav-link.active {
    background: transparent linear-gradient(180deg, #7D4444 0%, #FF0000 100%);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    cursor: pointer;
    position: relative;
    border-bottom: none !important;
}

.my_tab .nav-tabs .nav-link.active:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background: transparent linear-gradient(180deg, #7D4444 0%, #FF0000 100%);
}

/* my tabs css end  */

/* filter css start  */
.filter_title {
    padding-right: 48px;
}

.clear_filter {
    position: absolute;
    top: 0;
    right: 0;
}

.filter_fields {
    margin-left: 15px;
    position: relative;
}

.filter_fields .radio_group {
    max-height: 130px;
    overflow-y: auto;
}

.custom_filter {
    position: absolute;
    text-transform: capitalize;
    bottom: 0;
    right: 0;
    cursor: pointer;
}

.with_custom_filter .radio_field:last-child {
    padding-right: 95px;
}

/* fiter css end  */

/* my switch css start  */
.react-switch-bg {
    height: 18px !important;
    width: 31px !important;
    background: #807E7E !important;
    border-radius: 18px !important;
}

.on .react-switch-bg {
    background: #269B02 !important;
}

.theme_switch.on .react-switch-bg {
    background: transparent linear-gradient(180deg, #7D4444 0%, #FF0000 100%) !important;
}

.react-switch-bg div {
    display: none;
}

.react-switch-handle {
    height: 14px !important;
    width: 14px !important;
    top: 2px !important;
}

.on .react-switch-handle {
    transform: translateX(15px) !important;
}

.off .react-switch-handle {
    transform: translateX(2px) !important;
}

.my_switch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-right: 8px;
}

.my_switch.switch_with_inline_label {
    margin: 0px;
}

.my_switch.switch_with_inline_label .switch {
    display: flex;
    align-items: center;
    gap: 6px;
}

/* my switch css end  */
/* menu modal css start  */
.menu_modal .modal-dialog {
    margin: 0px;
    border-radius: 0px;
    margin-left: auto;
    margin-right: auto;
}

.my_modal.menu_modal .modal-content {
    border-radius: 0px;
    text-align: inherit;
    min-height: 100vh;
    border: none;
}

.redirect_button .modal_btn {

    border-radius: 4px;
    padding: 5px 12px;
    width: fit-content;

}

.redirect_button {
    display: flex;
    gap: 6px;
    margin-top: 8px;
    margin-bottom: 25px;
    flex-wrap: wrap;
}

.menu_single h6 {
    margin-bottom: 0px;
}

.menus .menu_single {
    display: flex;
    align-items: center;
    gap: 7px;
    cursor: pointer;
    color: black;
    text-decoration: none;
}

.menus .menu_single .icon {
    display: flex;
    align-items: center;
    justify-content: center
}

.menus {
    display: grid;
    row-gap: 16px;
}

/* menu modal css end  */


/* common css start  */


/* landing page css start  */
.congrates_img img {
    border-bottom: 4px solid #260073;
}

/* landing page css end  */

/* header start  */

.vendor_header .header {
    padding: 15px;
    /* background: #EFEFEF; */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    max-width: 420px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.vendor_header .header_right,
.vendor_header .header_left {
    display: flex;
    align-items: center;
    gap: 12px;
}

.vendor_header .header_right {
    gap: 20px;
}

.vendor_header .shopping_cart .number {
    background: #269B02;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    color: white;
    font-size: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0px;
    position: absolute;
    top: 0;
    right: -5px;
}

/* header end  */


/* dashboard css start  */
.dashboard .lcs_inner .icon img {
    height: 16px;
    width: auto;
}

.dashboard .lcs_inner .icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard .lcs_inner {
    display: flex;
    align-items: center;
    gap: 11px;
    padding-right: 22px;
}

.dashboard .long_card_single .arrow_forward {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 19px;
}

.dashboard .long_card_parent {
    display: grid;
    row-gap: 3px;
}

.dashboard .long_card_parent .long_card_single:first-child {
    border-radius: 9px 9px 0px 0px;
}

.dashboard .long_card_parent .long_card_single:last-child {
    border-radius: 0px 0px 9px 9px;
}

.contact_person_info {
    display: grid;
    row-gap: 8px;
}

.upload_logo .img_wrapper {
    width: 122px;
    height: 122px;
    border-radius: 9px;
    border: 1px solid #EFEFEF;
    padding: 5px;
    margin: auto;
}

.upload_logo .img_wrapper img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* dashboard css end  */

/* analytics page css start  */
.tvd_inner {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 5px solid #008DEA;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.pganalytics .bc_single {
    background: #EFEFEF;
    border-radius: 9px;
    padding: 18px 15px 12px 15px;
    text-align: inherit;
    min-height: inherit;
    justify-content: inherit;
}

.pganalytics .bc_single .inner {
    display: grid;
    row-gap: 2px;
}

.pganalytics .bc_single .inner .grad_text {
    font-size: 14px;
}

.pganalytics .big_cards {
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    margin-bottom: 8px;
}

.pganalytics .long_card_single {
    border-radius: 9px;
}

@media only screen and (max-width: 440px) {
    .mt-420 {
        margin-top: 21px;
    }
}

li.nav-item.menu_btn {
    margin-bottom: 10px;
}

/*Noor css start*/
.common {
    text-align: center;
}

.slick-slider {
    overflow: hidden;
}

a.shopping_cart img {
    height: 19px;
}

.normal_font {
    font-size: 14px;
}

.highlight_color {
    color: #008DEA !important;
}

.line_hight {
    line-height: 23px;
}

.main_body h6.r14.lh22 {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.our_product .main_body h6.r14.lh22 {
    /* -webkit-line-clamp: 12; */
    -webkit-line-clamp: unset;

}

.menu_single .icon img {
    width: 19px;
}

.contact_info h3 {
    font-size: 30px !important;
    margin-bottom: 9px;
}

.contact_info h4 {
    font-size: 18px;
    margin: 6px 0px;
    font-family: 'medium';
}

/*Noor css end*/


/* sanskar new css start  */
.navbar_icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon_color {
    background: transparent linear-gradient(180deg, #7D4444 0%, #FF0000 100%);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    cursor: pointer;
    font-size: 24px;
}

.myqrcode {
    overflow: auto;
}

.myqrcode .my_code {
    background: #EFEFEF;
    width: 432px;
    height: auto;
    margin: auto;
    padding: 22px;

}

.my_code .our_logo {
    text-align: center;
}

.my_code .our_logo img {
    width: 100px;
    margin: auto;
}

.my_code .code_container {
    border-radius: 9px;
    padding: 15px;
    background: white;
    width: 100%;
    height: 100%;
    margin: auto;
    margin-top: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 6px #00000029;
}

.my_code .code_container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.code_container .watermark {
    width: 38px;
    height: 38px;
    background: white;
    border: 1px solid grey;
    position: absolute;
    border-radius: 50%;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
    padding: 6px;
}

.code_container .watermark img {
    width: 100%;
    height: 100%;
}

.expired_plan {
    background: #ebebeb;
    padding: 15px;
    border-radius: 9px;
}

.form_field.custom_url .cu_inner {
    font-size: 14px;
    font-family: 'regular';
    padding: 10px;
    width: 100%;
    border: 1px solid black;
    border-radius: 9px;
    display: flex;
    gap: 2px;
}

.form_field.custom_url input {
    padding: 0px;
    border: none;
    border-radius: 0px;
}

.my_modal.modal {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.my_modal.modal_top.modal {
    display: block !important;
}

.my_modal.vendor_header_modal,
.my_modal.modal.d_block_modal {
    display: block !important;
}

.categories_in_accordion.accordion_big {
    max-width: 750px;
}

.my_tab.fit_width .nav-item {
    width: fit-content;
    min-width: 160px;
}

.my_tab.fit_width .nav-tabs {
    justify-content: center;
}

.textarea_text {
    white-space: pre-line;
}


.invoice .order_in_table th {
    background: #807E7E;
    color: white;
    font-size: 14px;
    font-family: 'regular';
    font-weight: 400;
    padding: 5px;
    line-height: 21px;
}

.order_in_table td {
    font-size: 14px;
    color: #000000;
    padding: 7px 5px;
    background: #EFEFEF;
    line-height: 21px;
}

.order_in_table td.amount {
    text-align: end;
    font-family: semibold;
}

.invoice_modal .modal-content {
    padding: 0px;
    text-align: inherit;
}

.tc_single {
    background: #EFEFEF;
    border-radius: 9px;
    border: 1px solid #C1C3C5;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    aspect-ratio: 1/1.5;
    position: relative;
}

.tc_single span.c_soon {
    font-size: 12px;
    font-family: 'medium';
    color: #FF7474;
    position: absolute;
    bottom: 7px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
}

.tc_single.active {
    background: #EFEFEF;
    border-color: #008DEA;
}

.tc_single .tcs_inner {
    text-align: center;
}

.tc_single .tcs_inner h5 {
    font-size: 17px;
    line-height: 26px;
    font-family: medium;
}

.tc_single .tcs_inner h6 {
    font-size: 13px;
    font-family: regular;
}

.tc_single .selected_template {
    position: absolute;
    top: 8px;
    right: 8px;
    color: #231F20;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.tc_single .selected_template span {
    font-size: 19px;
}

.themes_cards {
    display: grid;
    gap: 12px;
    /* flex-wrap: wrap; */
    /* justify-content: center; */
    grid-template-columns: repeat(3, 1fr);
}

/* new css start  */
.radio_field label input {
    width: fit-content;
}

.inline_radio_group.with_border {
    border: 1px solid #807E7E;
    background: white;
    padding: 10px;
    border-radius: 9px;
}

.inline_checkbox_group {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
}

.inline_checkbox_group .checkbox_single label {
    display: flex;
    align-items: center;
    gap: 8px;

}

.inline_checkbox_group .checkbox_single label input {
    width: fit-content;
}

.inline_radio_group {
    flex-wrap: wrap;
}

.form_field label {
    text-transform: capitalize;
}

.photos_video .v_slider_img {
    flex-wrap: wrap;
}

.photos_video .v_slider_img .vsi_single {
    aspect-ratio: 1 / 1;
    gap: 15px;
}

.photos_video .v_slider_img .vsi_single video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 7px;

}

.politician_card section.portal_logo {
    width: 150px;
    height: 150px;
    border-radius: 9px;
    border: 3px solid white;
    margin: auto;
    overflow: hidden;
    padding: 0px;
    margin-top: -55px;
    position: relative;
}

.politician_card section.portal_logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.journey_single {
    padding-left: 30px;
}


.journey_single .party_icon {
    height: 20px;
    position: absolute;
    top: 0;
    left: 0;
}

.journey_single::after {
    content: '';
    background: black;
    width: 1px;
    height: 100%;
    top: 20px;
    left: 9px;
    position: absolute;
}

.journey_single:last-child:after {
    height: 85%;

}

.journey_list {
    margin-top: 16px;
    margin-bottom: 32px;
    display: grid;
    row-gap: 25px;
}

.journey_detail h6 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
}

.journey_detail.expanded h6 {
    display: unset;
}

.photoandvideo .circle {
    width: 62px;
    height: 62px;
    background: transparent linear-gradient(180deg, #FF9900 0%, #FF9900 67%, #0DD200 100%) 0% 0% no-repeat padding-box;

    position: absolute;
    top: -31px;


}

.photoandvideo .circle_left {
    clip-path: circle(50% at 0 49%);
    left: 0;
}

.photoandvideo .circle_right {
    clip-path: circle(50% at 100% 50%);
    right: 0;
}

.photoandvideo .all_photos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    background: white;
}

.photoandvideo .all_videos {
    display: grid;
    row-gap: 9px;
}

.photoandvideo .all_videos video {
    width: 100%;
    height: auto;
}

.photoandvideo .all_photos img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
}

.public_comment .comment_single {
    border-radius: 9px;
    border: 1px solid #707070;
    padding: 14px 18px;
}

.public_comment .comment_single h6.m14 {
    position: relative;
}

.public_comment .comment_single h6.m14::after {
    content: '';
    background: black;
    position: absolute;
    top: -8px;
    width: 55px;
    height: 2px;
    left: 0;
}

.page .public_comment .slick-dots {
    bottom: -20px;
}

.r14 {
    line-height: 21px;
}

.short_info_inline {
    display: flex;
    flex-wrap: wrap;
}

.short_info_inline span {
    padding-left: 5px;
    padding-right: 5px;
    border-right: 1px solid black;
}

.short_info_inline span:first-child {
    padding-left: 0px;
}

.short_info_inline span:last-child {
    padding-right: 0px;
    border-right: none;
}

.my_center.my_three_theme_bg {
    margin-bottom: 24px;
}

.free_plan_hide {
    display: none;
}

/* .Travels .Change.Theme.Layout {
    display: none;
} */