.size_16 {
  font-size: 16px !important;
}

.size_18 {
  font-size: 18px !important;
}

.size_20 {
  font-size: 20px !important;
}

.medium {
  font-family: medium;
}

.semibold {
  font-family: semibold;
}

.bold {
  font-family: bold;
}

.hero_content {
  text-align: center;
  max-width: 1050px;
  width: 100%;
  margin: 0 auto;
}

.hero_content h1 {
  font-size: 70px;
  line-height: 100px;
  font-family: semibold;
  margin-bottom: 42px;
}
.eng_content .hero_content h1 {
  margin-bottom: 10px;
}
.eng_content .hero_content h2 {
 font-size: 34px;
 font-family: medium;
 color: black;
 margin-bottom: 0px;
}
.hero_content .animated_text {
  font-size: 28px;
  line-height: 36px;
}
.hero_content .line {
  height: 100px;
  width: 3px;
  background: black;
  margin: 8px auto 5px auto;
  position: relative;
}
.hero_content .line:before{
  width: 12px;
  height: 12px;
  border-radius: 50%;
  content: '';
  position: absolute;
  top: 0px;
  left: -4.53px;
  z-index: 9;
  background: black;
}
.hero_content .line:after{
  width: 12px;
  height: 12px;
  border-radius: 50%;
  content: '';
  position: absolute;
  bottom: 0px;
  left: -4.53px;
  z-index: 9;
  background: black;
}

.hero_content h2 {
  color: #007ed0;
  font-family: 'semibold';
  line-height: 40px;
}

.hero_content h2 {
  margin-bottom: 10px;
}

.hero {
  min-height: calc(100vh - 60px);
  margin-top: 60px;
  padding: 25px 0px;
  background: linear-gradient(-45deg, #e3d3d3, #fdfdfd, #fef7f7, #efe4e4);
  background-size: 400% 400%;
  animation: hero_bg_gradient 15s ease infinite;
}

@keyframes hero_bg_gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.animated_text {
  font-size: 22px;
  font-family: medium;
  background: linear-gradient(to right, #7D4444, #FF0000);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: block;
  animation: animateGradient 5s linear infinite;
}

@keyframes animateGradient {
  0% {
    color: #7D4444;
  }

  50% {
    color: #FF0000;
  }

  100% {
    color: #7D4444;
  }

}

.animated_text.white {
  background: linear-gradient(to right, #ffffff, #bebebe);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: block;
  animation: animateGradientWhite 5s linear infinite;
}

@keyframes animateGradientWhite {
  0% {
    color: #ffffff;
  }

  50% {
    color: #bebebe;
  }

  100% {
    color: #ffffff;
  }

}




.hero_btn h3 {
  font-size: 28px;
  font-family: 'medium';
}

.hero_btn h3 span {
  font-size: 39px;
  font-family: 'semibold';
}

.hero_btn img {
  position: absolute;
  margin-top: 12px;
  margin-left: 22px;
}


.hero_btn h5 {
  font-size: 18px;
  font-family: 'semibold';
}

.hero_btn {
  margin-top: 35px;
}

.theme_title h2,
.page_title h1 {
  font-size: 30px;
  font-family: 'semibold';
  margin-bottom: 15px;
}

.privacy_policy h2 {
  font-size: 26px;
  font-family: 'semibold';
  margin-bottom: 15px;
}

.website_pages.privacy_policy .page h3 {
  font-family: 'semibold';
  font-size: 22px;
  margin-top: 0px;
  margin-bottom: 15px;
}

.website_pages.privacy_policy .page h4 {
  font-family: 'semibold';
  font-size: 18px;
  margin-bottom: 15px;
}

.website_pages.privacy_policy .page li {
  margin-top: 8px;
}

.privacy_policy p {
  margin-bottom: 13px;
}

.color_title h2 {
  color: #FF0000;
}

.hiw_slider .carousel-indicators [data-bs-target] {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.showcase_slide .carousel-indicators [data-bs-target] {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #FF0000;
}

.hiw_slider .carousel-item img {
  position: relative;
  top: 4px;
  aspect-ratio: 3 / 2;
  object-fit: cover;
}

.hiw {
  background: black;
  padding: 75px 0px;
  border-radius: 60px;
}

.theme_title h2 span {
  font-size: 30px;
  font-family: 'semibold';
  margin-bottom: 15px;
  color: #FF0000;
}

.g_logos img:first-child {
  margin-right: 15px;
}

.border_title:after {
  content: '';
  width: 121px;
  height: 5px;
  position: absolute;
  left: 0;
  bottom: -16px;
  background: #FF0000;
}

.about_content p {
  font-size: 16px;
  margin-top: 40px;
}

.g_logos {
  margin-bottom: 25px;
}

.vertical_center {
  display: flex;
  align-items: center;
}

.showcase_slide {
  width: 199px;
  height: 424px;
  overflow: hidden;
  position: absolute !important;
  top: 11px;
  left: 12px;
  border-radius: 26px;
}

.relative {
  position: relative;
}

.screen_top {
  position: absolute;
  top: 11px;
  z-index: 9;
  left: 52px;
}

.showcase_mobile {
  margin-left: 155px;
}

.hiw_content {
  color: white;
}

.step_single h3 {
  opacity: .6;
  color: white;
  padding-top: 9px;
  font-size: 18px;
}

.step_single a {
  background: -webkit-linear-gradient(45deg, #7D4444, #FF0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  font-family: 'medium';
}

.step_single {
  padding-left: 85px;
}

.step_single span {
  position: absolute;
  background: #FF0000;
  color: white;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  text-align: center;
  left: 0;
  font-size: 37px;
  font-family: 'semibold';
  line-height: 65px;
}

.steps {
  margin: 30px 0px;
}

.cat_content h2 {
  font-size: 45px;
  font-family: 'medium';
  color: #FF0000;
  margin-bottom: 28px;
  max-width: 575px;
  width: 100%;
}

.cat_img {
  text-align: center;
  margin-bottom: 25px;

}

.cat_img img {
  max-width: 550px;
  width: 100%;
}

.cat_single {
  background-image: linear-gradient(to bottom, #FF0000, #7D4444);
  color: white;
  padding: 22px;
  border-radius: 25px;
  height: 100%;
}

.cat_single img {
  width: auto;
  height: 50px;
  margin-bottom: 10px;
}

.cat_single p {
  color: white;
  opacity: .8;
  margin: 0;
  padding-top: 4px;
  font-size: 16px;
}

.cs {
  margin-top: 20px !important;
}

.cat_single h4 {
  font-size: 20px;
  font-family: 'medium';
}

.stories {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}

.video_single video {
  width: 100%;
  height: auto;
  border-radius: 15px;
}

.cta_inner {
  display: flex;
  border-radius: 20px;
  overflow: hidden;
}

.cta_thought {
  font-size: 28px;
  width: 50%;
  background: black;
  color: white;
  padding: 45px;
}

.cta_btn {
  background: #FF0000;
  color: white;
  font-size: 24px;
  width: 50%;
  text-align: center;
  padding: 45px;
}

.cta_btn .theme_btn_site {
  margin-top: 12px;
}

.cta_btn .theme_btn_site a {
  border: 1px solid white;
}

.footer_logo {
  position: relative;
  top: -45px;
  margin-bottom: -21px;
  height: 150px;
}

.footer_content p {
  max-width: 798px;
  width: 100%;
  color: white;
  opacity: .6;
  font-family: light;
  margin: 0 auto;
}

.social_share {
  margin: 25px 0px;
}

.social_share a img {
  width: auto;
  height: 18px;
  margin: 0px 7px;
}

.footer_menu a {
  font-family: 'medium';
  text-decoration: none;
  color: white;
  opacity: .6;
  margin: 0px 18px;
}

.footer_menu a:hover {
  opacity: 1;
}

.website_pages .copyright {
  margin-bottom: 0;
  background: black;
  padding: 15px 0px;
  border-top: 1px solid #3d3d3d;
}

.copyright_text {
  text-align: center;
  color: white;
  opacity: .6;
  font-family: extralight;
}

.copyright_text span {
  color: white;
  font-family: extralight;
  margin-left: 40px;
  position: relative;
}

.copyright_text span:before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
  position: absolute;
  left: -26px;
  top: 5px;
}



/* may be refelected class  */
.website_pages section {
  margin-bottom: 45px;
}

.website_pages .theme_btn_site a {
  font-size: 20px;
  font-family: medium;
  text-decoration: none;
  background-image: linear-gradient(to right, #7D4444, #FF0000);
  color: white;
  padding: 12px 28px;
  border-radius: 10px;
  display: inline-block;
}

.website_pages .about {
  background-repeat: no-repeat;
  background-position: right;
  background-size: 50% 100%;
  height: 740px;
}

.website_pages .footer {
  background: #000000;
  text-align: center;
  border-radius: 60px 60px 0px 0px;
  margin-top: 95px;
  margin-bottom: 0 !important;
  padding-bottom: 20px;
}

.footer_whatsapp {
  position: fixed;
  z-index: 99;
  bottom: 40px;
  right: 40px;
}

.footer_whatsapp img {
  width: 60px;
}

.fs_logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 28px;
  gap: 10px;
}

.fs_logo img {
  height: 22px;
}

.fs_logo img:last-child {
  margin-top: 9px;
}

.website_pages .header {
  margin: 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 100%;
  background: white;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
}

.website_pages .logo img {
  height: 49px;
}

.website_pages #navbarText ul {
  justify-content: flex-end;
  width: 100%;
}

.website_pages #navbarText ul.dropdown-menu {
  min-width: 170px;
}


.website_pages .header nav.navbar {
  padding: 0;
}

.website_pages .header #navbarText ul li a {
  padding: 9px;
  font-size: 14px;
}

.website_pages button.navbar-toggler {
  padding: 0px;
  border: none;
}

.website_pages .navbar-toggler:focus {
  box-shadow: none !important;
}

.website_pages button[aria-expanded="true"] span.navbar-toggler-icon {
  background-image: url("../../public/assets/img/close.png") !important;
}

.website_pages button[aria-expanded="false"] span.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.menu_btn a {
  background: #FF0000;
  color: white;
  border-radius: 7px;
}

.pgtitle {
  font-size: 25px;
  font-family: 'light';
  position: relative;
  display: inline-block;
  padding-bottom: 7px;
}

.pgtitle_b_desc {
  font-size: 35px;
  font-family: 'medium';
  text-align: center;
  max-width: 840px;
  margin: auto;
}

.sitevg22 {
  height: 22px;
}

.sitevg30 {
  height: 30px;
}

.sitevg40 {
  height: 40px;
}

.pgtitle:after {
  content: '';
  position: absolute;
  width: 118px;
  height: 4px;
  margin: auto;
  bottom: 0;
  left: 0;
  right: 0;
  background: #FF0000;
}

.website_pages .navbar_dropdown li a {
  padding: 0px !important;
  font-size: 14px;
  font-family: 'medium';
  color: black;
}

.website_pages .navbar_dropdown {
  padding: 0px;
  margin-top: -8px;
  border: none;
  padding: 14px;
  border-radius: 0px;

}

.website_pages .page {
  padding-top: 100px;
}

.website_pages .page h3 {
  font-family: 'semibold';
  font-size: 18px;
  margin-top: 22px;
}

/* why bb section start  */
.why_businessbaab {
  padding: 50px 0px;
}

.why_businessbaab .about_tech_img img {
  width: 100%;
}

.why_businessbaab h5 {
  font-size: 25px;
  font-family: regular;
  line-height: 38px;
}

.why_businessbaab .left {
  text-align: right;
}

.why_businessbaab .right {
  text-align: left;
}

.site_aboutus .about_banner {
  text-align: center;
}

.site_aboutus .about_banner img {
  max-width: 660px;
  width: 100%;
  margin: auto;
}

.sect_gap {
  height: 75px;
}

.website_pages.site_aboutus .why_businessbaab {
  margin-bottom: 0px;
}

/* vision and mission section css start  */
.vision_mission {
  background: #7D4444;
  padding-top: 62px;
}

.vision_mission .left .content {
  font-size: 20px;
  font-family: regular;
  display: grid;
  row-gap: 15px;
  color: white;
  max-width: 490px;
}

.vision_mission .right {
  /* text-align: right; */
}

.vision_mission .right img {
  /* max-width: 420px; */
  width: 100%;
  margin-left: auto;
}

/* pricing section css start  */

.pricing_plan .ppi_sect .title {
  position: relative;
  font-size: 25px;
  padding-bottom: 12px;
  margin-bottom: 22px;
}

.pricing_plan .ppi_sect .subtitle {
  line-height: 33px;
  font-size: 22px;
  font-family: medium;
  max-width: 422px;
  margin: auto;
  text-align: center;
}

.pricing_plan .ppi_sect.ppi_sect2 .subtitle {
  max-width: 492px;

}

.pricing_plan .ppi_sect .title:after {
  content: '';
  position: absolute;
  width: 120px;
  height: 4px;
  background: #FF0000;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0px;
}

.pricing_plan .plans {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-radius: 15px;
  overflow: hidden;
  width: fit-content;
  margin: 25px auto;
}

.pricing_plan .plans_single {
  min-height: 172px;
  padding: 0px 15px;
  min-width: 238px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}

.pricing_plan .plans_single.monthly {
  background: #7D4444;
}

.pricing_plan .plans_single.yearly {
  background: #FF0000;
  position: relative;
}

.pricing_plan .plans_single .tag {
  font-size: 14px;
  font-family: medium;
  color: #000000;
  background: white;
  border-radius: 0px 0px 8px 8px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: fit-content;
  min-width: 80px;
  min-height: 18px;
}

.pricing_plan .plans_single .per_month {
  font-size: 22px;
  font-family: medium;
  background: black;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
  padding: 1px 0px;
}

.pricing_plan .plans_single h4 {
  font-size: 50px;
  font-family: semibold;
}

.pricing_plan .plans_single h5 {
  font-size: 25px;
  font-family: medium;
}

.pricing_plan .ppi_sect1 {
  background: #EFEFEF;
  border-radius: 30px 30px 0px 0px;
}

.pricing_plan .ppi_sect2 {
  background: #BCBCBC;
  border-radius: 0px 0px 30px 30px;
}

.pricing_plan .ppi_sect {
  padding: 45px 15px;
}

.pricing_plan .feature_points .fp_single {
  font-size: 19px;
  font-family: medium;
  position: relative;
  padding-left: 28px;
  line-height: 33px;
  margin-bottom: 5px;
}

.pricing_plan .feature_points {
  margin-top: 30px;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}

.pricing_plan .feature_points .fp_single span {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0px;
}

/* pricing section css end*/


/* site categories page css start  */
.categories_in_accordion {
  max-width: 480px;
  margin: auto;
}

.curve_style .theme_accordion.style_2 button.accordion-button {
  border-radius: 30px;

}

.curve_style .theme_accordion.style_2 button.accordion-button.collapsed {
  background: #EFEFEF;
  border: 1px solid #BFBFBF;
}

.curve_style .theme_accordion.style_2 button.accordion-button {
  background: #cecece;
  border: 1px solid black;
}

.curve_style .theme_accordion.style_2 h5.product_name {
  color: black;
}

.curve_style .theme_accordion.style_2 .accordion-body {
  padding: 15px;
  background: #FFFFFF;
  border: 1px solid black;
  border-top: none;
  margin-top: -17px;
  padding-top: 30px;
  border-radius: 10px;
}

.curve_style .theme_accordion.style_2 .points.accordion-body {
  padding-left: 0px;

}

/* how work section start  */
.how_work_inner {
  padding: 25px;
  background: #EFEFEF;
  border-radius: 20px;
  margin: auto;
  max-width: 746px;
}

.how_work_inner h4 {
  font-size: 20px;
  font-family: medium;
}

.how_work_inner ol {
  padding-left: 25px;
}

.how_work_inner ol li {
  margin-top: 15px;
  font-size: 18px;
}

.promoter_form .upload_photo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.promoter_form .upload_photo label {
  font-size: 12px;
  font-family: regular;

}

.promoter_form .img_container {
  width: 90px;
  height: 90px;
}

.promoter_form .img_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 9px;
}

@media screen and (min-width: 1200px) {
  .menu_btn {
    margin-left: 8px;
  }
}

@media screen and (min-width: 992px) {
  .hiw_content {
    padding-left: 25px;
  }
}

@media screen and (max-width: 991px) {
  .why_businessbaab .about_tech_img img {
    height: 360px;
  }

  .why_businessbaab .left {

    margin-bottom: 22px;
  }

  .why_businessbaab .right {

    margin-top: 22px;
  }

  .hiw_content {
    color: white;
    margin-top: 35px;
  }

  .stories {
    display: block !important;
    white-space: nowrap;
    overflow: auto;
  }

  .video_single {
    display: inline-block;
    width: 75%;
    padding-right: 7px;
  }

  .hero_content h1 {
    font-size: 50px;
    line-height: 71px;
  }
}

@media screen and (max-width: 767px) {
  .website_pages .about {
    background-size: 100% 100% !important;
  }
  .eng_content .hero_content h1 {
    margin-bottom: 10px;
    font-size: 28px;
}
.eng_content .hero_content h2 {
  font-size: 22px;
  margin-top: -14px;
  max-width: 210px;
  margin-left: auto;
  margin-right: auto;
}
.hero_content .line {
  height: 55px;
  width: 2px;

}
.hero_content .line:before, .hero_content .line:after {
  width: 8px;
  height: 8px;
  left: -3px;
}
.hero_content .animated_text {
  font-size: 20px;
  line-height: 28px;
  max-width: 325px;
  margin-left: auto;
  margin-right: auto;
}
.hero_btn {
  margin-top: 18px;
}
  .footer_whatsapp {

    bottom: 15px;
    right: 15px;
  }

  .footer_whatsapp img {
    width: 40px;
  }

  .hero_content h1 {
    font-size: 35px;
    line-height: 52px;
  }

  .privacy_policy h2 {
    font-size: 22px;
  }

  .website_pages.privacy_policy .page h3 {
    font-size: 20px;

  }

  .website_pages.privacy_policy .page h4 {
    font-size: 16px;

  }

  .privacy_policy p {
    font-size: 14px;
  }

  .website_pages.privacy_policy .page li {
    font-size: 14px;
  }

  .website_pages.terms_conditon p {
    font-size: 14px;
  }

  .website_pages .page h3 {
    font-size: 14px;
  }


  .website_pages .about {
    height: auto;
  }

  .hiw {
    padding: 35px 8px;
    border-radius: 20px;
  }

  .website_pages .theme_btn_site a {
    font-size: 17px;
    padding: 10px 12px;
  }
  

  .website_pages .cta_btn .theme_btn_site a {
    font-size: 15px;
    padding: 10px 12px;
  }

  .animated_text {
    font-size: 17px;
  }

  .step_single h3 {
    font-size: 16px;
    line-height: 23px;
    padding-top: 4px;
  }

  .category .theme_btn {
    display: none;
  }

  .cat_img img {
    margin-top: 15px;
  }

  .cta_inner {
    display: block;
  }

  .cta_thought,
  .cta_btn {
    width: 100%;
  }

  .cta_thought {
    font-size: 25px;
    text-align: center;
  }

  .website_pages .footer_menu a {
    margin: 18px 0px;
    display: block;
  }

  .website_pages .footer {
    padding-bottom: 3px;
    border-radius: 35px 35px 0px 0px;
  }

  .vision_mission .right img {
    max-width: 245px;
  }

  .vision_mission .right {
    text-align: center;
    margin-top: 12px;
    padding-top: 25px;
  }
}

@media screen and (max-width: 575px) {

  .pricing_plan .plans_single {
    min-width: 135px;
    min-height: 140px;

  }

  .pricing_plan .plans_single .per_month {
    font-size: 18px;

  }

  .pricing_plan .plans {
    border-radius: 12px;
    width: 100%;
    margin: 15px auto;
  }

  .pricing_plan .ppi_sect {
    padding: 20px 10px;
  }

  .pricing_plan .plans_single .tag {
    font-size: 13px;
  }

  .pricing_plan .ppi_sect1 {
    border-radius: 15px 15px 0px 0px;
  }

  .ppi_sect1 .theme_btn.short_btn {
    padding: 15px 20px;
  }

  .pricing_plan .ppi_sect2 {

    border-radius: 0px 0px 15px 15px;
  }

  .pricing_plan .ppi_sect .title {
    font-size: 20px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .pricing_plan .ppi_sect .title:after {
    width: 65px;
    height: 3px;

  }

  .pricing_plan .ppi_sect .subtitle {
    line-height: normal;
    font-size: 16px;
  }

  .pricing_plan .feature_points {
    margin-top: 15px;
  }

  .pricing_plan .feature_points .fp_single {
    font-size: 14px;
    line-height: normal;
    margin-bottom: 4px;
    padding-left: 24px;
  }

  .pricing_plan .feature_points .fp_single span {
    font-size: 20px;
  }

  .pricing_plan .plans_single h4 {
    font-size: 30px;

  }

  .pricing_plan .plans_single h5 {
    font-size: 18px;
  }

  .cat_single {
    padding: 10px;
    border-radius: 15px;
  }

  .cat_single p {
    font-size: 12px;
  }

  .categories .cs {
    padding: 0px 4px;
  }

  .row.categories {
    margin: -4px;
  }

  .categories .cs {
    margin-top: 7px !important;
  }

  .cat_single img {
    height: 40px;
  }

  .cat_single h4 {
    font-size: 18px;
  }

  .hero_content h2 {
    font-size: 21px;
    line-height: 29px;
    margin-top: 21px;
  }

  .hero_btn h3 {
    font-size: 16px;
  }

  .hero_btn h3 span {
    font-size: 24px;
  }

  .hero_btn img {
    margin-top: 23px;
    margin-left: 5px;
    width: 27px;
  }

  /*.hero_content h2:nth-child(3) {*/
  /*    display: none;*/
  /*}*/
  .theme_title h2,
  .theme_title h2 span {
    font-size: 25px;
  }

  .website_pages .page_title h1 {
    font-size: 25px;
  }

  .showcase_mobile {
    margin-left: 0;
    text-align: center;
  }

  .showcase_slide {
    left: 0;
    right: 0;
    top: 10px;
    margin: 0 auto;
  }

  .screen_top {
    left: 0;
    right: 0;
    top: 9px;
    margin: 0 auto;
  }

  .about_content p {
    font-size: 14px;
  }

  .website_pages .about {
    background-image: none !important;
  }

  .hero_content h1 {
    font-family: 'bold';
    font-size: 30px;
  }

  .cat_content h2 {
    font-size: 35px;
    line-height: 42px;
    margin-bottom: 15px;
  }

  .video_single video {
    border-radius: 10px;
  }

  .cta_thought,
  .cta_btn {
    padding: 20px;
  }

  .cta_thought,
  .cta_btn {
    font-size: 22px;
  }

  .footer_logo {
    width: 200px;
    top: -30px;
    margin-bottom: -5px;
    height: inherit;
  }

  .website_pages .copyright_text span:before {
    display: none;
  }

  .website_pages .copyright_text span {
    margin-left: 0;
    display: block;
  }

  .website_pages .page {
    padding-top: 80px;
  }

  .pgtitle {
    font-size: 20px;
  }

  .sitevg40 {
    height: 20px;
  }

  .pgtitle_b_desc {
    font-size: 22px;
  }

  .how_work_inner {
    padding: 15px;
    border-radius: 12px;
  }

  .how_work_inner h4 {
    font-size: 18px;

  }

  .how_work_inner ol li {
    margin-top: 10px;
    font-size: 14px;
  }

  .sect_gap {
    height: 45px;
  }

  .why_businessbaab {
    padding: 30px 0px;
  }

  .sitevg30 {
    height: 30px;
  }

  .why_businessbaab h5 {
    font-size: 18px;
    line-height: normal;
  }

  .why_businessbaab .left {
    text-align: center;
    margin-bottom: 12px;
  }

  .why_businessbaab .right {
    text-align: center;
    margin-top: 12px;
  }

  .vision_mission {
    padding-top: 30px;
  }


  .vision_mission .left .content {
    font-size: 16px;
    row-gap: 10px;
  }

  .fs_logo {
    margin-bottom: 20px;
  }

  .mbv {
    width: 100% !important;
  }



}

/* noor sir css  */
.product_single.out_off_stock .product_img {
  opacity: 0.5;
}

.product_single.out_off_stock .product_desc {
  opacity: 0.5;
}

/* sanskar css start  */
.demo_website_links {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.dwl_single {
  background: #EFEFEF 0% 0% no-repeat padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 5px;
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: space-between;
  padding: 10px 12px;
  font-size: 14px;
  color: #FF0000;
  gap: 10px;
}

.dwl_single .left {
  background: transparent linear-gradient(180deg, #7D4444 0%, #FF0000 100%);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.dwl_single .left span.material-symbols-outlined {
  font-size: 20px;
}

.view-demo-website {
  padding: 22px 15px;
  max-width: 420px;
  margin: 0 auto;
}

.view-demo-website .modal-header {
  justify-content: center;
}

.view-demo-website .modal-header button {
  display: none;
}


.pulse {
  background-color: yellow;
  -webkit-animation: blink 800ms infinite;
          animation: blink 800ms infinite;
}
@-webkit-keyframes blink { 50% { background-color: red; }}
      @keyframes blink { 50% { background-color: red; }}

/*=============*/


@-webkit-keyframes pulse {
0% { background-color: red; }
100% { background-color: yellow; }
}
@keyframes pulse {
0% { background-color: red; }
100% { background-color: yellow; }
}