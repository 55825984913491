.changethemecolor {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.color_div {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 4px solid #efefef;
  position: relative;
}
.selected_color {
  background: black;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -3px;
  right: -3px;
}
.selected_color span {
  font-size: 11px;
  color: white;
}
.color_div.ED00FD_5200F6 {
  background: transparent linear-gradient(180deg, #ed00fd 0%, #5200f6 100%);
}
.color_div.FDBA00_F60000 {
  background: transparent linear-gradient(180deg, #fdba00 0%, #f60000 100%);
}
.color_div._50FF6D_15D100 {
  background: transparent linear-gradient(180deg, #50ff6d 0%, #15d100 100%);
}
.color_div._41FFFA_00BDB8 {
  background: transparent linear-gradient(180deg, #41fffa 0%, #00bdb8 100%);
}
.color_div.FFA723_C87800 {
  background: transparent linear-gradient(180deg, #ffa723 0%, #c87800 100%);
}
.color_div.FD2F96_DA006D {
  background: transparent linear-gradient(180deg, #fd2f96 0%, #da006d 100%);
}
.color_div.B4B4B4_000000 {
  background: transparent linear-gradient(180deg, #b4b4b4 0%, #000000 100%);
}
.color_div._3075FF_0036A3 {
  background: transparent linear-gradient(180deg, #3075ff 0%, #0036a3 100%);
}
.color_div.D3BF9C_947D53 {
  background: transparent linear-gradient(180deg, #d3bf9c 0%, #947d53 100%);
}
.color_div.D9D9D9_979695 {
  background: transparent linear-gradient(180deg, #d9d9d9 0%, #979695 100%);
}

/* Vendor website theme css start  */

/* css for theme ED00FD_5200F6   */
.ED00FD_5200F6 {
  .blue {
    color: #5900f6;
  }
  .purple {
    color: #5900f6;
  }
  .theme_btn.btn_border {
    background: white;
    color: #5900f6;
    border-color: #5900f6;
  }
  .grad_text,
  .icon_color {
    background: transparent linear-gradient(180deg, #ed00fd 0%, #5200f6 100%);
    -webkit-background-clip: text;
    background-clip: text;
  }
  .grad_text.grad_text_white {
    color: white;
  }
  .gt_border::after {
    background: transparent linear-gradient(180deg, #ed00fd 0%, #5200f6 100%);
  }
  &.template_2 .travel_services .products_parent .left {
    background: transparent linear-gradient(180deg, #ed00fd 0%, #5200f6 100%);
  }
  .theme_btn,
  .about_portal .head {
    background: transparent linear-gradient(180deg, #ed00fd 0%, #5200f6 100%);
  }
  .head_top_bg::before {
    background: transparent linear-gradient(180deg, #5900f6 0%, #00000000 100%);
  }
  section.contact_now {
    background: #e1f3ff;
  }
  .template_3 .sect_bg {
    background: #e1f3ff !important;
  }
  &.template_4 .sect_bg {
    background: #e1f3ff;
  }
  .portal_page_spacing.template_3 {
    background: linear-gradient(180deg, transparent, #e1f3ff);
  }
  .portal_page_spacing .header {
    background: #e1f3ff !important;
  }
}
/* css for theme ED00FD_5200F6   */

/* css for theme FDBA00_F60000   */
.FDBA00_F60000 {
  .blue {
    color: #f60000;
  }
  .purple {
    color: #f60000;
  }
  .theme_btn.btn_border {
    background: white;
    color: #f60000;
    border-color: #f60000;
  }
  .grad_text,
  .icon_color {
    background: transparent linear-gradient(180deg, #fdba00 0%, #f60000 100%);
    -webkit-background-clip: text;
    background-clip: text;
  }
  .grad_text.grad_text_white {
    color: white;
  }
  .gt_border::after {
    background: transparent linear-gradient(180deg, #fdba00 0%, #f60000 100%);
  }
  &.template_2 .travel_services .products_parent .left {
    background: transparent linear-gradient(180deg, #fdba00 0%, #f60000 100%);
  }
  .gt_border.white_border::after {
    background: white;
  }
  .theme_btn,
  .about_portal .head {
    background: transparent linear-gradient(180deg, #fdba00 0%, #f60000 100%);
  }
  .head_top_bg::before {
    background: transparent linear-gradient(180deg, #fdba00 0%, #f60000 100%);
  }
  section.contact_now {
    background: #ffdcdc;
  }
  .template_3 .sect_bg {
    background: #ffdcdc !important;
  }
  &.template_4 .sect_bg {
    background: #ffdcdc;
  }
  .portal_page_spacing.template_3 {
    background: linear-gradient(180deg, transparent, #ffdcdc);
  }
  .portal_page_spacing .header {
    background: #ffdcdc !important;
  }
}

/* css for theme FDBA00_F60000   */

/* css for theme _50FF6D_15D100   */
._50FF6D_15D100 {
  .blue {
    color: #15d100;
  }
  .purple {
    color: #15d100;
  }
  .theme_btn.btn_border {
    background: white;
    color: #15d100;
    border-color: #15d100;
  }
  .grad_text,
  .icon_color {
    background: transparent linear-gradient(180deg, #50ff6d 0%, #15d100 100%);
    -webkit-background-clip: text;
    background-clip: text;
  }
  .grad_text.grad_text_white {
    color: white;
  }
  .gt_border::after {
    background: transparent linear-gradient(180deg, #50ff6d 0%, #15d100 100%);
  }
  &.template_2 .travel_services .products_parent .left {
    background: transparent linear-gradient(180deg, #50ff6d 0%, #15d100 100%);
  }
  .gt_border.white_border::after {
    background: white;
  }
  .theme_btn,
  .about_portal .head {
    background: transparent linear-gradient(180deg, #50ff6d 0%, #15d100 100%);
  }
  .head_top_bg::before {
    background: transparent linear-gradient(180deg, #50ff6d 0%, #15d100 100%);
  }
  section.contact_now {
    background: #e1ffdd;
  }
  .template_3 .sect_bg {
    background: #e1ffdd !important;
  }
  &.template_4 .sect_bg {
    background: #e1ffdd;
  }
  .portal_page_spacing.template_3 {
    background: linear-gradient(180deg, transparent, #e1ffdd);
  }
  .portal_page_spacing .header {
    background: #e1ffdd !important;
  }
}

/* css for theme _50FF6D_15D100   */

/* css for theme _41FFFA_00BDB8   */
._41FFFA_00BDB8 {
  .blue {
    color: #00bdb8;
  }
  .purple {
    color: #00bdb8;
  }
  .theme_btn.btn_border {
    background: white;
    color: #00bdb8;
    border-color: #00bdb8;
  }
  .grad_text,
  .icon_color {
    background: transparent linear-gradient(180deg, #41fffa 0%, #00bdb8 100%);
    -webkit-background-clip: text;
    background-clip: text;
  }
  .grad_text.grad_text_white {
    color: white;
  }
  .gt_border::after {
    background: transparent linear-gradient(180deg, #41fffa 0%, #00bdb8 100%);
  }
  &.template_2 .travel_services .products_parent .left {
    background: transparent linear-gradient(180deg, #41fffa 0%, #00bdb8 100%);
  }
  .gt_border.white_border::after {
    background: white;
  }
  .theme_btn,
  .about_portal .head {
    background: transparent linear-gradient(180deg, #41fffa 0%, #00bdb8 100%);
  }
  .head_top_bg::before {
    background: transparent linear-gradient(180deg, #41fffa 0%, #00bdb8 100%);
  }
  section.contact_now {
    background: #defffe;
  }
  .template_3 .sect_bg {
    background: #defffe !important;
  }
  &.template_4 .sect_bg {
    background: #defffe;
  }
  .portal_page_spacing.template_3 {
    background: linear-gradient(180deg, transparent, #defffe);
  }
  .portal_page_spacing .header {
    background: #defffe !important;
  }
}

/* css for theme _41FFFA_00BDB8   */

/* css for theme FFA723_C87800   */
.FFA723_C87800 {
  .blue {
    color: #c87800;
  }
  .purple {
    color: #c87800;
  }
  .theme_btn.btn_border {
    background: white;
    color: #c87800;
    border-color: #c87800;
  }
  .grad_text,
  .icon_color {
    background: transparent linear-gradient(180deg, #ffa723 0%, #c87800 100%);
    -webkit-background-clip: text;
    background-clip: text;
  }
  .grad_text.grad_text_white {
    color: white;
  }
  .gt_border::after {
    background: transparent linear-gradient(180deg, #ffa723 0%, #c87800 100%);
  }
  &.template_2 .travel_services .products_parent .left {
    background: transparent linear-gradient(180deg, #ffa723 0%, #c87800 100%);
  }
  .gt_border.white_border::after {
    background: white;
  }
  .theme_btn,
  .about_portal .head {
    background: transparent linear-gradient(180deg, #ffa723 0%, #c87800 100%);
  }
  .head_top_bg::before {
    background: transparent linear-gradient(180deg, #ffa723 0%, #c87800 100%);
  }
  section.contact_now {
    background: #fff1db;
  }
  .template_3 .sect_bg {
    background: #fff1db !important;
  }
  &.template_4 .sect_bg {
    background: #fff1db;
  }
  .portal_page_spacing.template_3 {
    background: linear-gradient(180deg, transparent, #fff1db);
  }
  .portal_page_spacing .header {
    background: #fff1db !important;
  }
}

/* css for theme FFA723_C87800   */

/* css for theme FD2F96_DA006D   */
.FD2F96_DA006D {
  .blue {
    color: #da006d;
  }
  .purple {
    color: #da006d;
  }
  .theme_btn.btn_border {
    background: white;
    color: #da006d;
    border-color: #da006d;
  }
  .grad_text,
  .icon_color {
    background: transparent linear-gradient(180deg, #fd2f96 0%, #da006d 100%);
    -webkit-background-clip: text;
    background-clip: text;
  }
  .grad_text.grad_text_white {
    color: white;
  }
  .gt_border::after {
    background: transparent linear-gradient(180deg, #fd2f96 0%, #da006d 100%);
  }
  &.template_2 .travel_services .products_parent .left {
    background: transparent linear-gradient(180deg, #fd2f96 0%, #da006d 100%);
  }
  .gt_border.white_border::after {
    background: white;
  }
  .theme_btn,
  .about_portal .head {
    background: transparent linear-gradient(180deg, #fd2f96 0%, #da006d 100%);
  }
  .head_top_bg::before {
    background: transparent linear-gradient(180deg, #fd2f96 0%, #da006d 100%);
  }
  section.contact_now {
    background: #ffdbed;
  }
  .template_3 .sect_bg {
    background: #ffdbed !important;
  }
  &.template_4 .sect_bg {
    background: #ffdbed;
  }
  .portal_page_spacing.template_3 {
    background: linear-gradient(180deg, transparent, #ffdbed);
  }
  .portal_page_spacing .header {
    background: #ffdbed !important;
  }
}

/* css for theme FD2F96_DA006D   */

/* css for theme B4B4B4_000000   */
.B4B4B4_000000 {
  .blue {
    color: #000000;
  }
  .purple {
    color: #000000;
  }
  .theme_btn.btn_border {
    background: white;
    color: #000000;
    border-color: #000000;
  }
  .grad_text,
  .icon_color {
    background: transparent linear-gradient(180deg, #b4b4b4 0%, #000000 100%);
    -webkit-background-clip: text;
    background-clip: text;
  }
  .grad_text.grad_text_white {
    color: white;
  }
  .gt_border::after {
    background: transparent linear-gradient(180deg, #b4b4b4 0%, #000000 100%);
  }
  &.template_2 .travel_services .products_parent .left {
    background: transparent linear-gradient(180deg, #b4b4b4 0%, #000000 100%);
  }
  .gt_border.white_border::after {
    background: white;
  }
  .theme_btn,
  .about_portal .head {
    background: transparent linear-gradient(180deg, #b4b4b4 0%, #000000 100%);
  }
  .head_top_bg::before {
    background: transparent linear-gradient(180deg, #b4b4b4 0%, #000000 100%);
  }
  section.contact_now {
    background: #dcdcdc;
  }
  .template_3 .sect_bg {
    background: #dcdcdc !important;
  }
  &.template_4 .sect_bg {
    background: #dcdcdc;
  }
  .portal_page_spacing.template_3 {
    background: linear-gradient(180deg, transparent, #dcdcdc);
  }
  .portal_page_spacing .header {
    background: #dcdcdc !important;
  }
}

/* css for theme B4B4B4_000000   */

/* css for theme _3075FF_0036A3   */
._3075FF_0036A3 {
  .blue {
    color: #0036a3;
  }
  .purple {
    color: #0036a3;
  }
  .theme_btn.btn_border {
    background: white;
    color: #0036a3;
    border-color: #0036a3;
  }
  .grad_text,
  .icon_color {
    background: transparent linear-gradient(180deg, #3075ff 0%, #0036a3 100%);
    -webkit-background-clip: text;
    background-clip: text;
  }
  .grad_text.grad_text_white {
    color: white;
  }
  .grad_text.grad_text_white {
    color: white;
  }
  .gt_border::after {
    background: transparent linear-gradient(180deg, #3075ff 0%, #0036a3 100%);
  }
  &.template_2 .travel_services .products_parent .left {
    background: transparent linear-gradient(180deg, #3075ff 0%, #0036a3 100%);
  }
  .gt_border.white_border::after {
    background: white;
  }
  .gt_border.white_border::after {
    background: white;
  }
  .theme_btn,
  .about_portal .head {
    background: transparent linear-gradient(180deg, #3075ff 0%, #0036a3 100%);
  }
  .head_top_bg::before {
    background: transparent linear-gradient(180deg, #3075ff 0%, #0036a3 100%);
  }
  section.contact_now {
    background: #dce8ff;
  }
  .template_3 .sect_bg {
    background: #dce8ff !important;
  }
  &.template_4 .sect_bg {
    background: #dce8ff;
  }
  .portal_page_spacing.template_3 {
    background: linear-gradient(180deg, transparent, #dce8ff);
  }
  .portal_page_spacing .header {
    background: #dce8ff !important;
  }
}

/* css for theme _3075FF_0036A3   */

/* css for theme D3BF9C_947D53   */
.D3BF9C_947D53 {
  .blue {
    color: #947d53;
  }
  .purple {
    color: #947d53;
  }
  .theme_btn.btn_border {
    background: white;
    color: #947d53;
    border-color: #947d53;
  }
  .grad_text,
  .icon_color {
    background: transparent linear-gradient(180deg, #d3bf9c 0%, #947d53 100%);
    -webkit-background-clip: text;
    background-clip: text;
  }
  .grad_text.grad_text_white {
    color: white;
  }
  .gt_border::after {
    background: transparent linear-gradient(180deg, #d3bf9c 0%, #947d53 100%);
  }
  &.template_2 .travel_services .products_parent .left {
    background: transparent linear-gradient(180deg, #d3bf9c 0%, #947d53 100%);
  }
  .gt_border.white_border::after {
    background: white;
  }
  .theme_btn,
  .about_portal .head {
    background: transparent linear-gradient(180deg, #d3bf9c 0%, #947d53 100%);
  }
  .head_top_bg::before {
    background: transparent linear-gradient(180deg, #d3bf9c 0%, #947d53 100%);
  }
  section.contact_now {
    background: #fff5e1;
  }
  .template_3 .sect_bg {
    background: #fff5e1 !important;
  }
  &.template_4 .sect_bg {
    background: #fff5e1;
  }
  .portal_page_spacing.template_3 {
    background: linear-gradient(180deg, transparent, #fff5e1);
  }
  .portal_page_spacing .header {
    background: #fff5e1 !important;
  }
}

/* css for theme D3BF9C_947D53   */

/* css for theme D9D9D9_979695   */
.D9D9D9_979695 {
  .blue {
    color: #979695;
  }
  .purple {
    color: #979695;
  }
  .theme_btn.btn_border {
    background: white;
    color: #979695;
    border-color: #979695;
  }
  .grad_text,
  .icon_color {
    background: transparent linear-gradient(180deg, #d9d9d9 0%, #979695 100%);
    -webkit-background-clip: text;
    background-clip: text;
  }
  .grad_text.grad_text_white {
    color: white;
  }
  .gt_border::after {
    background: transparent linear-gradient(180deg, #d9d9d9 0%, #979695 100%);
  }
  &.template_2 .travel_services .products_parent .left {
    background: transparent linear-gradient(180deg, #d9d9d9 0%, #979695 100%);
  }
  .gt_border.white_border::after {
    background: white;
  }
  .theme_btn,
  .about_portal .head {
    background: transparent linear-gradient(180deg, #d9d9d9 0%, #979695 100%);
  }
  .head_top_bg::before {
    background: transparent linear-gradient(180deg, #d9d9d9 0%, #979695 100%);
  }
  section.contact_now {
    background: #efefef;
  }
  .template_3 .sect_bg {
    background: #efefef !important;
  }
  &.template_4 .sect_bg {
    background: #efefef;
  }
  .portal_page_spacing.template_3 {
    background: linear-gradient(180deg, transparent, #efefef);
  }
  .portal_page_spacing .header {
    background: #efefef !important;
  }
}

/* css for theme D9D9D9_979695   */

/* Vendor website theme css start  */
