.portal_page_spacing {
    padding: 15px;
    max-width: 420px;
    min-height: calc(100vh - 55px);
    width: 100%;
    margin: auto;
    margin-top: 55px;
}

/* portal header css start  */
.portal_header .header {
    padding: 15px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    max-width: 420px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.portal_header .header_right,
.portal_header .header_left {
    display: flex;
    align-items: center;
    gap: 12px;
}

.portal_header.header_hide .header {
    display: none;
}

.portal_header .header_right {
    gap: 20px;
}

.portal_header .shopping_cart .number {
    background: black;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    color: white;
    font-size: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0px;
    position: absolute;
    top: -4px;
    right: -4px;
}

.menu_icon_home {
    box-shadow: 0px 3px 6px #00000029;
    width: 43px;
    height: 43px;
    border-radius: 50%;
    position: absolute;
    top: -40px;
    right: 15px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 9;
}

.menu_icon_home img {
    width: 22px;
    aspect-ratio: inherit !important;
}

/* portal header css start  */


.sect_padding {
    padding: 30px 0px;
}

.sect_title {
    font-size: 20px;
    font-family: medium;
    margin-bottom: 20px !important;
}

section.banner img {
    width: 100%;
    aspect-ratio: 3/2;
    object-fit: cover;
}

section.portal_logo {
    padding: 12px 0px;
    text-align: center;
}

section.portal_logo img {
    height: 100px;
    width: auto;
    max-width: 120px;
    object-fit: contain;
}

.template_2 .home_two section.portal_logo img {
    height: 48px;
}

.template_2 .home_two section.portal_logo {
    text-align: start;
}

.about_portal .head {
    background: #5900F6;
    padding: 10px;
    border-radius: 9px 9px 0px 0px;
}

.about_portal.ht_banner .head {
    padding: 15px;
    border-radius: 15px;
    margin-right: -15px;
    margin-left: -15px;
}

.about_portal.ht_banner .from_left_right {
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 15px;
    overflow: hidden;
}


h2.make_logo {
    font-size: 24px;
    font-family: 'TT Ramillas Initials Trl Md';
    position: relative;
    text-transform: capitalize;
    display: inline;
    /* display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden; */
}

h2.make_logo::before {
    content: '';
    background: #d8d8d8;
    width: 15px;
    height: 50px;
    position: absolute;
    border-radius: 50%;
    left: 32px;
    top: -10px;
    rotate: -38deg;
    z-index: -1;
}



.about_portal .main_body {
    background: #EFEFEF;
    padding: 15px 15px 25px 15px;
    border-radius: 0px 0px 9px 9px;
    text-align: center;
}

.product_single {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 9px;
    overflow: hidden;
}

.template_2 .product_single {
    display: flex;
}

.template_2 .product_single .product_img {
    width: 35%;
}

.template_2 .product_single .product_desc {
    width: 65%;
    padding: 10px;
}

.template_2 .product_single .product_desc .pd_inner {
    display: flex;
    flex-direction: column-reverse;
    gap: 5px;
}

.template_2 .product_single .product_desc .pd_inner h6 {
    -webkit-line-clamp: 2 !important;
    font-size: 16px !important;
    font-family: medium !important;
}

.template_3 .product_single .product_desc .pd_inner h6 {
    -webkit-line-clamp: 1 !important;

}

.page .template_2 .theme_btn.short_btn.view_btn {

    font-size: 16px;

}



.product_single.out_off_stock {
    position: relative;
}

.product_single.out_off_stock:after {
    content: "Out of Stock";
    position: absolute;
    background: #ffffffcf;
    top: 50%;
    left: 0;
    right: 0;
    color: #ff0000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    margin: auto;
    transform: translateY(-50%);
    font-family: 'medium';
}

.travel_services .products_parent {
    grid-template-columns: repeat(1, 1fr);
}

.travel_services .products_parent .product_desc {
    background: #EFEFEF;
    border-radius: 8px;
    padding: 7px 10px;
    display: flex;
    gap: 15px;
    align-items: center;
}

.travel_services .products_parent .product_desc .left img {
    width: 62px;
    height: 62px;
}

.products_parent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 12px;
    grid-row-gap: 12px;
}

.template_2 .products_parent {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 17px;
    grid-row-gap: 17px;
}

.product_single .product_img {
    width: 100%;
    aspect-ratio: 1/1;
    background: #E3E3E3;
}

.product_single .product_img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.product_single .product_desc {
    padding: 10px 10px 15px 10px;
}

.Travels .product_desc .only_one_line {
    -webkit-line-clamp: inherit;
}

section.contact_now {
    background: #E1F3FF;
    padding: 30px 15px;
}

.template_3 section.contact_now {
    background: white !important;
    border-radius: 20px;
}


.contact_parent {
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    /* justify-content: space-between; */
}

.contact_single .cs_inner {
    box-shadow: 0px 0px 24px #00000029;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 15px;
    width: 65px;
    height: 65px;
}

.contact_single .cs_inner img {
    width: 100%;
}

.contact_now .sect_divider {
    margin: 20px 0px;
    background: black;
    opacity: 0.2;
}

.contact_now .social_media {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    flex-wrap: wrap;
}

.contact_now .social_media .social_media_single {
    width: 13%;
}

.social_media_single img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.pcontact .address li {
    list-style-type: none;
    position: relative;
}

.pcontact .address li span.material-symbols-outlined.location_icon {
    position: absolute;
    left: -28px;
    top: -2px;
    opacity: 0.8;
}

.pg_qrocde .qrcode_img {
    width: 220px;
    height: 220px;
    margin: auto;
}

.pg_qrocde .qrcode_img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.psdetail .product_short_info .pic_price .pic {
    /* width: 65px;
    height: 65px; */
}

.product_short_info .price {
    flex-grow: 1;
    width: 100%;
}

.product_short_info .product_id {
    background: #EFEFEF;
    width: fit-content;
    font-size: 10px;
    font-family: 'medium';
    border-radius: 2px;
    padding: 2px 4px;
}

.cart_single .product_img {
    width: 62px;
    height: 62px;
    aspect-ratio: 1/1;
}

.cart_single {
    display: flex;
}

.cart_single .right {
    display: flex;
    gap: 10px;
    align-items: center;
    background: #EFEFEF;
    padding: 4px 10px;
    width: 100%;
    justify-content: space-between;
}

.cart_single .right .form_field input {
    padding: 6px;
    text-align: center;
}

.cart_single .close_media {
    width: 20px !important;
    height: 20px !important;
    right: -6px;

}

.cart_single .product_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cart_single .right .detail h6 {
    margin-top: 3px;
}

.cart_single_parent {
    display: grid;
    row-gap: 18px;
}

.pay_me {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    flex-direction: column;
}

.template_2 .pay_me {
    flex-direction: initial;
    justify-content: inherit;
    row-gap: 28px;
}

.template_2 .pay_me_single {
    gap: 10px;
    justify-content: inherit;
    flex-direction: column;
}

.template_2 .pay_me .pay_me_single {
    min-width: 112px;
}

.template_2 .pay_me .pay_me_single .left {
    gap: 0px;
    justify-content: center;
}

.template_2 .pay_me .pay_me_single .left h6 {
    display: none;
}

.template_2 .pay_me_single .right {
    border-radius: 19px;
    font-size: 14px;
}


.pay_me_single .pay_icon {
    width: 58px;
    height: 58px;
    overflow: hidden;
    border-radius: 50%;

}

.pay_me .pay_me_single .left {
    display: flex;
    align-items: center;
    gap: 12px;
}

.pay_me_single {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: space-between;
}

.pay_me_single .right {
    background: white;
    border-radius: 30px;
    padding: 7px 14px;
    display: flex;
    align-items: center;
    color: black;
    border: 1px solid black;
    gap: 3px;
    cursor: pointer;
}

.pay_me_single .right span {
    font-size: 17px;
}

.pay_me_single .pay_icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* our product page css start  */
.our_product .about_portal .head {
    background: none;
}

.our_product .about_portal .main_body {
    background: none;
    padding: 0px;
    border-radius: 0px;
}

.menu_modal .sect_divider {
    background: #dadada;
}

/* home three css start  */
.template_3.home_three .about_portal .head {
    background: black;
    border-radius: 50px 50px 0px 0px;
    margin-top: 105px;

}

.template_3 .about_portal .head_top_bg {
    position: relative;
}

.template_3 .about_portal .head_top_bg:before {
    content: '';
    position: absolute;
    left: 0;
    top: -178px;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.template_3 .about_portal.ht_banner .from_left_right {
    margin-top: -125px;
    margin-left: 15px;
    margin-right: 15px;
}

.template_3 section.our_product {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
}

.template_3 section.our_product .theme_btn {
    margin-right: 15px;
}

.template_3 .product_single {
    display: inline-block;
    width: 65%;
    white-space: normal;
    margin-right: 25px;
}

.template_3 .product_single .product_img {
    width: 100%;
}

.template_3 .product_single .product_desc {
    width: 100%;
    padding: 10px 10px 20px 10px;
    background: white;
}

.template_3 .product_single .product_desc .pd_inner {
    flex-direction: column;
}

.template_3 .product_single .product_desc .pd_inner .price {
    font-size: 29px;
}

.template_3 .product_single .product_desc .pd_inner h6 {
    font-size: 18px !important;
}

.template_3 .products_parent {
    display: block;
    white-space: nowrap;
    overflow: auto;
    margin-right: -15px;
}

.template_3 .contact_single .cs_inner {
    box-shadow: none;
    background: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: unset;
    padding: 0px;
    width: 20px;
    height: 20px;
}

.template_3 .contact_single {
    width: 100%;
    background: white;
    border-radius: 21px;
    border: 1px solid black;
    padding: 10px 12px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
}

.template_3 .contact_single:after {
    content: "\2192";
    position: absolute;
    top: 50%;
    color: black;
    font-size: 25px;
    font-weight: 900;
    line-height: 0px;
    right: 10px;
    transform: translateY(-50%);
}

.template_3 .contact_single .vg12 {
    display: none;
}

.template_3 .contact_parent {
    gap: 10px;
}

.template_3 .contact_single .grad_text {
    font-size: 16px;
    text-align: start;
}

/* home three css end  */


/* home four css start  */
.template_4 .home_four section.portal_logo {
    margin: 0px -15px;
    display: flex;
    align-items: end;
    justify-content: end;
    overflow: hidden;
    padding-top: 86px;
    padding-bottom: 30px;
    margin-bottom: 10px;
}

.template_4 .home_four section.portal_logo img {
    max-width: 175px;
    object-fit: contain;
    height: auto;
}

.template_4 section.portal_logo .logo_circle {
    width: 90%;
    box-shadow: 0px 0px 20px #00000029;
    background: white;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -14%;
    position: relative;
}

.template_4 section.portal_logo .inner_border {
    position: absolute;
    width: 84%;
    height: 84%;
    border-radius: 50%;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);
}

.template_4 section.portal_logo .inner_border .ib_inner {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px dashed #000000;
    border-radius: 50%;
    left: 0;
    top: 0;
    animation: circlemoveright 90s linear infinite;
}

@keyframes circlemoveright {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(1turn);
    }
}

.template_4 .from_left_right {
    margin-left: 0px;
    margin-right: 0px;
}

.template_4 section.banner img {
    border-radius: 35px;
}

.template_4 .our_product .sect_title {
    text-align: center;
}

.template_4 .product_single {
    box-shadow: none;
    border-radius: 0px;
    overflow: unset;
    position: relative;
}

.template_4 .product_single::after {
    content: '';
    position: absolute;
    background: #000000;
    width: 1px;
    height: 70%;
    bottom: 0;
    left: 0;
}

.template_4 .product_single::before {
    content: '';
    position: absolute;
    background: #000000;
    width: 50%;
    height: 1px;
    bottom: 0;
    left: 0;
}

.template_4 .contact_now.from_left_right {
    margin-left: -15px;
    margin-right: -15px;
}

.template_4 .product_single .product_img {
    border-radius: 35px;
    overflow: hidden;
}

.template_4 .contact_single .cs_inner {
    border-radius: 21px;
    width: 73px;
    height: 73px;
}

.template_4 .circle_1 {

    width: 72px;
    height: 72px;
    border-radius: 50%;
    position: absolute;
    top: -8%;
    left: 14%;
    z-index: -1;

}

.template_4 .circle_4 {

    width: 69px;
    height: 69px;
    border-radius: 50%;
    position: absolute;
    bottom: 0px;
    left: 11%;
    z-index: -1;

}

.template_4 .circle_2 {

    width: 75px;
    height: 75px;
    border-radius: 50%;
    position: absolute;
    top: 2%;
    left: 8%;
    z-index: -1;

}

.template_4 .circle_3 {

    width: 49px;
    height: 49px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(90%);
    left: -20px;
    z-index: -1;

}

.template_4 .circle_5 {

    width: 106px;
    height: 106px;
    border-radius: 50%;
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    left: -15%;
    z-index: -1;

}

.template_4 section.our_product.sect_padding.sect_bg {
    background: transparent !important;
}

.template_4 section.our_product .theme_btn {
    border-radius: 35px;
}



/* css for desktop view start */
.desktop_hide {
    display: inherit;
}

.desktop_header {
    display: none;
}

.menu_icon_home.desktop_hide {
    display: flex;
}

.mobile_hide {
    display: none;
}

.template_3 .travel_services .products_parent .product_desc {
    background: transparent;
    border-radius: 38px;
    padding: 12px 18px;
    border: 1px solid black;
}

.template_3 .travel_services .products_parent {
    display: grid;
    margin-right: 0px;
}

.template_4 .travel_services .products_parent .product_desc {
    background: transparent;
    border-radius: 0px;
    padding: 10px;
    position: relative;
}

.template_4 .travel_services .products_parent .product_desc::after {
    content: '';
    width: 1px;
    height: 50%;
    background: black;
    position: absolute;
    bottom: 0;
    left: 0;
}

.template_4 .travel_services .products_parent .product_desc::before {
    content: '';
    width: 50%;
    height: 1px;
    background: black;
    position: absolute;
    bottom: 0;
    left: 0;
}

.template_4 section.our_product.travel_services {
    padding: 15px;
    border-radius: 0px;
    margin-top: 15px;
    margin-left: -15px;
    margin-right: -15px;
}

@media only screen and (min-width: 992px) {
    .website_pages .copyright {

        margin-bottom: -20px !important;
    }

    .portal_page_spacing {
        max-width: inherit;
        padding: 0px;
        margin: 0px;

    }

    .from_left_right {
        margin-left: 0px;
        margin-right: 0px;
    }

    .mobile_hide {
        display: block;
    }

    .desktop_hide {
        display: none;
    }

    .sect_title {
        text-align: center;
    }

    section.banner img {
        width: 98%;
        margin-left: auto;
        margin-right: auto;
    }

    .from_top {
        margin-top: 0px;
    }

    .products_parent {
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 16px;
        grid-row-gap: 16px;
    }

    .menu_icon_home.desktop_hide {
        display: none;
    }

    .desktop_header {
        padding: 12px 20px;
        background: white;
        box-shadow: 0px 0px 25px #00000029;
        border-radius: 9px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        margin: 15px 0px;
    }

    .desktop_btn {
        width: fit-content !important;
        min-width: 330px;
        margin-right: auto;
        margin-left: auto;
    }

    .template_3 section.our_product .theme_btn {
        margin-right: auto;
    }

    .desktop_header .menus {
        display: flex;
        gap: 30px;
    }

    .desktop_sect_width {
        max-width: 700px;
        width: 100%;
        margin: auto;
    }

    .desktop_header .portal_logo img {
        height: 52px;
    }

    .contact_now.from_left_right {
        margin-left: 0px;
        margin-right: 0px;
        text-align: center;
    }

    .contact_parent {
        justify-content: center;
    }

    .pcontact .contact_parent {
        justify-content: inherit;
    }

    .pay_me {
        flex-direction: inherit;
        column-gap: 40px;
        justify-content: center;
    }

    .contact_now .social_media {
        justify-content: center;
    }

    .psdetail .product_short_info .pic_price .pic img {
        object-fit: cover;
    }

    .contact_now .social_media .social_media_single {
        width: 40px;
    }

    .page {
        padding-bottom: 20px;
    }

   

    .portal_page_spacing .footer {
        padding-bottom: 20px;
    }

    .dtp {
        padding-top: 20px;
    }

    .about_portal.ht_banner .head {
        border-radius: 0px;
        margin-right: 0px;
        margin-left: 0px;
    }

    .template_2 .desktop_header {
        padding: 0px;
        background: transparent;
        box-shadow: none;
    }

    .template_2 .products_parent {
        grid-template-columns: repeat(3, 1fr);
    }

    .template_2 .travel_services .products_parent {
        grid-template-columns: repeat(2, 1fr);
    }

    .template_2 .travel_services .products_parent .left {
        margin: -7px 0px -7px -10px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 10px;
    }

    .template_2.template_3 .travel_services .products_parent .left {
        margin: 0px;
        border-radius: 0px;
        display: flex;
        align-items: center;
        padding: 0px;
    }



    .template_3.home_three .about_portal .head {
        background: transparent;
        border-radius: 0px;
        margin-top: 0px;
    }

    .template_3.home_three .about_portal .conbg {
        background: black;
        border-radius: 75px 75px 0px 0px;
        margin-top: 215px;
        position: relative;
        padding: 0px 35px 35px 35px;
    }

    .template_3 .about_portal.ht_banner .from_left_right {
        position: relative;
        top: -108px;
        margin-bottom: -90px;

    }

    .template_3 .about_portal .head_top_bg:before {
        z-index: 0;
    }

    .template_3 .desktop_header {
        position: relative;
        z-index: 2;
    }

    .template_3 .home_two .menus .menu_single {
        color: white;
    }

    .template_3 .products_parent {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        margin-right: 0px;
    }

    .template_3 .product_single {
        width: 100%;
        margin-right: 0px;
    }

    .template_3 section.our_product {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .template_2 .pay_me {
        justify-content: center;
    }

    .template_3 .contact_single {
        width: 33%;
    }

    .theme_4_desktop_banner {
        display: flex;
        max-width: 1000px;
        margin: auto;
        justify-content: center;
        align-items: center;
        padding: 40px 0px;
    }

    .template_4 .home_four section.portal_logo {
        padding-top: 18px;
        padding-bottom: 18px;
        margin-bottom: 0px;
        width: 40%;
        border-right: 2px solid black;
    }

    .template_4 section.about_portal {
        width: 60%;
        padding-left: 32px;
    }
    .template_4 .desktop_inner_page section.about_portal {
        width: 100%;
        padding-left: inherit;
    }

    .template_4 section.about_portal .head {
        text-align: left !important;
    }

    .template_4 .desktop_inner_page section.about_portal .head {
        text-align: center !important;
    }

    .template_4 section.about_portal .head h5 {
        font-size: 35px;
        font-family: semibold;
        text-transform: capitalize;
    }

    .template_4 section.about_portal .main_body {
        text-align: left;
    }

    .template_4 section.about_portal .main_body h6.r14 {
        font-size: 24px;
        line-height: 35px;
        -webkit-line-clamp: 3;
    }

    .template_4 .desktop_inner_page section.about_portal .main_body h6.r14 {
        -webkit-line-clamp: inherit;
        text-align: center;
    }

    .template_4 section.about_portal .main_body .grad_text {
        font-size: 18px;
    }

    .template_4 .contact_now.from_left_right {
        margin-left: 0px;
        margin-right: 0px;
    }

    .template_4 .desktop_header {
        padding: 16px 0px;
        background: transparent;
        box-shadow: none;
        border-radius: 0px;
        justify-content: center;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }

    .template_4 .desktop_header .left {
        display: none;
    }

    .theme_4_desktop_banner .sect_bg {
        display: none;
    }

    .sect_title {
        font-size: 30px;
        margin-bottom: 30px !important;
    }

    .template_4 section.banner img {
        border-radius: 25px;
    }

    .template_4 section.our_product.travel_services {
        padding: 30px;
        max-width: 800px;
        margin: auto;
        border-radius: 32px;
        margin-top: 15px;
    }

    .template_4 .travel_services .products_parent {
        grid-template-columns: repeat(2, 1fr);
    }

}

/* css for desktop view end */