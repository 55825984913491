.cursor {
    cursor: pointer;
}
.modal-body .r16 {
    line-height: 25px;
}
button.save_info {
    border: none;
    padding: 7px 15px;
    border-radius: 5px;
}
.account_date b {
    margin-top: 5px;
    display: block;
}